import { Injectable } from '@angular/core';
import { FullMetadata, ListResult, Storage, UploadResult, getBlob, getDownloadURL, getMetadata, list, ref, uploadBytes, uploadString } from '@angular/fire/storage';
import { EMPTY, Observable, catchError, combineLatest, concatMap, finalize, forkJoin, from, map, mergeMap, of, switchMap, take, tap, toArray } from 'rxjs';
import { IFileInfo } from '../components/file-selector/file-selector.component';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public isPreview = false;
  private compressedImagesPath = 'gallery/images-compressed';
  private unCompressedImagesPath = 'gallery/images';
  private compressedPreviewImagesPath = 'gallery/images-compressed-preview';
  private unCompressedPreviewImagesPath = 'gallery/images-preview';
  constructor(
    private storage: Storage,
    private loadingService: LoadingService
  ) {}
  
  public addImageToFirestore(
    accessCode: string,
    guestName: string,
    fileInfoArr: IFileInfo[]
  ): Observable<UploadResult[]> {
    const cleanedGuestName = this.removeIllegalCharacters(guestName);
    this.loadingService.updateText('Uploading ...');
  
    const uploadTasks$ = from(fileInfoArr).pipe(
      concatMap((fileInfo, index) => {
        const name = `${this.getInvertedTimestamp()}_${fileInfo.file.name}`;
        const originalStorageRef = ref(
          this.storage,
          `${accessCode}/${this.getUnCompressedImagePath()}/${name}`
        );
        const compressedStorageRef = ref(
          this.storage,
          `${accessCode}/${this.getCompressedImagePath()}/${name}`
        );

        const compressionOptions = {
          maxSizeMB: 0.3, 
          maxWidthOrHeight: 800, 
          useWebWorker: true
        };
  
        const compressedImage$ = from(
          imageCompression(fileInfo.file, compressionOptions)
        ).pipe(
          switchMap((compressedFile: File) => {
            return from(
              uploadBytes(compressedStorageRef, compressedFile, {
                contentType: compressedFile.type,
                customMetadata: { accessCode, guestName: cleanedGuestName }
              })
            );
          })
        );
  
        const originalUpload$ = from(
          uploadBytes(originalStorageRef, fileInfo.file, {
            customMetadata: { accessCode, guestName: cleanedGuestName }
          })
        ).pipe(take(1));
        // Run both uploads in parallel for each file
        return combineLatest([originalUpload$, compressedImage$]).pipe(
          mergeMap(([originalResult, compressedResult]) => [originalResult, compressedResult]),
          tap(() => {
            const uploadedCount = index + 1;
            this.loadingService.updateText(`Uploaded ${uploadedCount} of ${fileInfoArr.length}...`);
          }),
          catchError((error) => {
            console.error(`Upload failed for file: ${fileInfo.file.name}`, error);
            return EMPTY; // Continue with the next file
          })
        );
      }),
      toArray() // Collect all results into a single array
    );
  
    return of({}).pipe(
      tap(() => this.loadingService.show()), // Show loading spinner
      switchMap(() => uploadTasks$), // Trigger the uploads
      finalize(() => {
        this.loadingService.updateText(); // Reset the loading text to default
        this.loadingService.hide(); // Hide loading spinner
      })
    );
  }
  

  public getPaginatedDownloadUrlsAndMetadata(  accessCode: string,maxResults: number, pageToken: string|null): Observable<{ files: { url: string, metadata: FullMetadata }[], nextPageToken: string|null, totalCount:number }> {
    const storageRef = ref(this.storage, `${accessCode}/${this.getCompressedImagePath()}`);
    const listOptions = { maxResults, pageToken };

    return from(list(storageRef, listOptions)).pipe(
      mergeMap((res: ListResult) => {
        const fileObservables = res.items.map((item) =>{
          return forkJoin({
            url: of(this.generateTokenlessURL(`${accessCode}/${this.getUnCompressedImagePath()}/${item.name}`)),
            metadata: from(getMetadata(item)),
          }).pipe(catchError((error) => {
            return of(null); // Continue with the next file
          }))
        }
        );
        return fileObservables.length
          ? forkJoin(fileObservables).pipe(
              map((files) => ({
              
                files: files.filter(Boolean) as [], 
                nextPageToken: res.nextPageToken || null,
                totalCount: res.items.length,
              }))
            )
          : of({ files: [], nextPageToken: null, totalCount: 0 });
      })
    );
  }

  public getBlobAsObs(directory: string){
    const storageRef = ref(this.storage, directory);
    return from(getBlob(storageRef))
  }

  public async getBlobAsPromise(directory: string){
    const storageRef = ref(this.storage, directory);
    return getBlob(storageRef)
  }

  public wrapObservableLoading<T>(obs:Observable<T>):Observable<T>{
    return of({}).pipe(
      tap(() => this.loadingService.show()), // Show loading here
      switchMap(()=> obs), // Load host photo,
      finalize(() => this.loadingService.hide()) // Hide loading on completion or error
    )
  }
  
  public getCompressedImagePath():string{  
    return this.isPreview?this.compressedPreviewImagesPath:this.compressedImagesPath;
  }

  public getUnCompressedImagePath():string{
    return this.isPreview?this.unCompressedPreviewImagesPath:this.unCompressedImagesPath;
  }


  private removeIllegalCharacters(filename: string): string {
    // Define illegal characters and remove them
    const illegalCharacters = /[\/:*?"<>|\\]/g; // Adding backslash for Windows
    return filename.replace(illegalCharacters, '');
  }
  private getInvertedTimestamp() {
    const maxTimestamp = 99999999999999;
    const currentTimestamp = parseInt(new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14));
    return maxTimestamp - currentTimestamp;
  }
  private generateTokenlessURL(filePath:string):string {
    // URL-encode the file path so that slashes and special characters are properly represented
    const encodedFilePath = encodeURIComponent(filePath);
    
    // Construct and return the non-tokenized Firebase Storage URL
    return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.projectId}.appspot.com/o/${encodedFilePath}?alt=media`;
  }
  
}
