import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';
import { FirestoreService } from '../services/firestore.service';
import { StorageService } from '../services/storage.service';
import { ThemeService } from '../services/theme.service';


export const accessCodeGuard: CanActivateFn = (route, state) => {
  const accessCode = route.paramMap.get('accessCode') || '';
  const fireStoreService = inject(FirestoreService);
  const storageService = inject(StorageService);
  const themeService = inject(ThemeService);
  return isValidAccessCode(accessCode,fireStoreService,storageService,themeService)
};

function isValidAccessCode(code: string , fireStoreService:FirestoreService,storageService:StorageService,themeService:ThemeService): Observable<boolean> | boolean {  
  return fireStoreService.accessGranted? fireStoreService.accessGranted:fireStoreService.incrementAccess(code).pipe(
    tap((accessGranted) => { 
      const theme = fireStoreService.publicData?.theme;
      themeService.setTheme(theme);
      console.log('App Guard Access Granted: ', accessGranted);
    }),
    switchMap((accessGranted) => 
      fireStoreService.getIsPreviewActive(code).pipe(
        tap((isPreviewActive) => { 
          storageService.isPreview = isPreviewActive;
          console.log('App Guard Preview Active: ', isPreviewActive);
        }),
        map(() => accessGranted)
      )
    )
  ); 
}
