// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

:root {
  --bs-link-hover-color-rgb: #2f2f2f;
  --bs-link-color-rgb: #000000;
}

:root {
  --bs-link-hover-color-rgb: #2f2f2f;
  --bs-link-color-rgb: #000000;
}

.drop-container {
  height: 185px;
  background-color: #f7f7f7;
  cursor: pointer;
}

a {
  display: inline-block;
  cursor: pointer;
  color: var(--card-stroke-color);
}
a :hover {
  color: #1f1f1f;
}

.se-btn-send {
  margin: 30px 0px 20px 0px;
  width: 100%;
}

.media-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: thin solid transparent;
  box-shadow: 0px 0px 5px 10px lightgray;
  animation: 3s pulse 2s infinite ease-in-out;
}

.media-button .fas.fa-video {
  font-size: 120px;
  color: black;
}

.media-button .fas.fa-camera {
  font-size: 120px;
  color: black;
}

.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  min-width: 200px;
}

@media (max-width: 430px) {
  .media-button {
    width: 180px;
    height: 180px;
  }
  .media-button .fas.fa-video {
    font-size: 80px;
  }
  .media-button .fas.fa-camera {
    font-size: 80px;
  }
}
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f0f0f0;
  --bs-btn-close-opacity: 1;
}

.btn:disabled {
  background: rgba(108, 117, 125, 0.1803921569);
  color: #A1A1A1;
  border: none;
}

.btn:disabled {
  background: rgba(108, 117, 125, 0.1803921569);
  color: #A1A1A1;
  border: none;
}

.se_upload_thumbnail {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.list-unstyled {
  margin-bottom: 0px;
}

.se-upload-list {
  padding: 10px 10px 0px 10px;
  text-align: left;
  background-color: #f0f0f0;
}

.se-card-spacing,
.se-card-selector {
  text-align: center;
  padding: 30px 30px 30px 30px;
  margin-bottom: 30px;
}

.se-card-selector {
  border-radius: 0.6rem;
  border-width: 0.2rem;
  border-color: var(--card-stroke-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
