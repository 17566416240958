// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

.card-fixed-size {
  padding-top: 0.2rem;
  height: 355px; /* Set your desired card height */
  width: 300px;
  display: flex;
  flex-direction: column;
}

.card-fixed-size .p-1 {
  height: 300px; /* Adjust this to control image container height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-fixed-size img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Your existing styles */
.fas.fa-image {
  color: black;
}

.fas.fa-cloud-upload {
  color: black;
}

/* Scroll up Container Styles */
.scroll-up-container {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1080;
}

.z-2000 {
  z-index: 2000;
}

footer.fixed-bottom {
  pointer-events: none; /* Prevents the footer from blocking clicks */
}

footer.fixed-bottom button {
  pointer-events: auto; /* Allows the button to be clickable */
}

.circle {
  display: flex;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
}

.alert {
  text-align: center;
  font-size: 1rem;
  margin: auto;
  padding: 0.5rem;
}

.se-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.se-scroll {
  width: var(--card-width);
}

.se-card-img {
  padding: 8px;
}

.btn:active {
  background-color: var(--card-background-color);
}

.se-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--card-background-color);
  border-width: 2.3px;
  border-color: var(--card-stroke-color);
  transition: transform 0.3s ease;
}
.se-upload-button:hover {
  transform: scale(1.08);
}

.se-float-container {
  position: fixed;
  bottom: 0px;
  margin-bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.se-gallery-card-photo[theme=chic] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border: double;
  border-width: 0.3rem;
  border-radius: 0rem;
  outline-color: var(--card-stroke-color);
  outline: solid;
  outline-width: 0.12rem;
}

.se-gallery-card-photo[theme=joy] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border-radius: 0.6rem;
  border-width: 0.2rem;
}

.se-gallery-card-photo[theme=fun] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  box-shadow: 4px 4px var(--card-stroke-color);
  border-radius: 0.5rem;
  border-width: 0.15rem;
}

.card-fixed-size img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Your existing styles */
.fas.fa-image {
  color: black;
}

.fas.fa-cloud-upload {
  color: black;
}

/* Scroll up Container Styles */
.scroll-up-container {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1080;
}

.z-2000 {
  z-index: 2000;
}

footer.fixed-bottom {
  pointer-events: none; /* Prevents the footer from blocking clicks */
}

footer.fixed-bottom button {
  pointer-events: auto; /* Allows the button to be clickable */
}

.se-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.se-scroll {
  width: var(--card-width);
}

.se-card-img {
  padding: 8px;
}

.se-gallery-card-photo[theme=chic] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border: double;
  border-width: 0.3rem;
  border-radius: 0rem;
  outline-color: var(--card-stroke-color);
  outline: solid;
  outline-width: 0.12rem;
}

.se-gallery-card-photo[theme=joy] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border-radius: 0.6rem;
  border-width: 0.2rem;
}

.se-gallery-card-photo[theme=fun] {
  box-sizing: border-box;
  margin-top: 2.2rem;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  box-shadow: 4px 4px var(--card-stroke-color);
  border-radius: 0.5rem;
  border-width: 0.15rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
