// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

:root {
  --card-radius: 16px;
  --card-background-color: #ffffff;
  --card-stroke-color: #1d1d1d;
  --card-font-family: "Abel", system-ui;
  --card-width: 340px;
}

[theme=joy] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #202020;
}

[theme=chic] {
  --se-hostname-font: "abel";
  --se-hostname-font-size: 2.6rem;
  --se-primary: #0e0e0e;
}

[theme=fun] {
  --se-hostname-font: "Arya";
  --se-hostname-font-size: 2.5rem;
  --se-hostname-line-height: 2.6rem;
  --se-primary: #202020;
}

.se-header {
  --_se-hostname-font: var(--se-hostname-font, "abel");
  --_se-primary: var(--se-primary, "black");
  --_se-hostname-line-height: var(--se-hostname-line-height, 2.5rem);
  --_se-hostname-font-size: var(--se-hostname-font-size, 40px);
  align-items: center;
  margin-top: 15px;
  width: var(--card-width);
}

.card-header-description {
  position: relative;
  top: 22px;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  margin-bottom: 10px;
}

.card-header-hostname {
  font-size: var(--_se-hostname-font-size);
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: var(--_se-hostname-font);
  font-style: normal;
  text-align: center;
  line-height: var(--_se-hostname-line-height);
  text-wrap: balance;
}

.card-header-logo {
  width: 66px;
  position: absolute;
  top: -42px;
}

.card-header[theme=chic] {
  box-sizing: border-box;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border: double;
  border-width: 0.3rem;
  border-radius: 0rem;
  outline-color: var(--card-stroke-color);
  outline: solid;
  outline-width: 0.12rem;
}

.card-header[theme=joy] {
  box-sizing: border-box;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  border-radius: 0.6rem;
  border-width: 0.2rem;
}

.card-header[theme=fun] {
  box-sizing: border-box;
  font-family: var(--card-font-family);
  border-color: var(--card-stroke-color);
  background-color: var(--card-background-color);
  box-shadow: 4px 4px var(--card-stroke-color);
  border-radius: 0.5rem;
  border-width: 0.15rem;
}

.height-75px {
  height: 75px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
