import { Injectable, ComponentRef, ViewContainerRef } from '@angular/core';
import { Subject, Observable, throwError, of } from 'rxjs';
import { takeUntil, switchMap, catchError, finalize, take } from 'rxjs/operators';
import { ModalComponent } from '../components/modal/modal.component';
import { StorageService } from './storage.service';
import { IFileInfo } from '../components/file-selector/file-selector.component';

interface ModalSetup {
  componentRef: ComponentRef<ModalComponent>;
  modalInstance: any;
  onDestroy: Subject<void>;
  cleanUp: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private storageService: StorageService) {}

  public openSendMediaModal(viewContainerRef: ViewContainerRef, accessCode: string, fileInfo: IFileInfo[]): Observable<any> {
    const { modalInstance, onDestroy, cleanUp } = this.openModal(viewContainerRef, 'SEND');

    return this.subscribeToModalEvents(modalInstance, onDestroy, cleanUp, () => {
        
        return this.storageService.addImageToFirestore(accessCode, modalInstance.userName,fileInfo);
    });
  }

  public openDropModal(viewContainerRef: ViewContainerRef): Observable<any> {
    const { modalInstance, onDestroy, cleanUp } = this.openModal(viewContainerRef, 'DROP');

    return this.subscribeToModalEvents(modalInstance, onDestroy, cleanUp);
  }

  public openWarningModal(viewContainerRef: ViewContainerRef): Observable<any> {
    const { modalInstance, onDestroy, cleanUp } = this.openModal(viewContainerRef, 'WARNING');

    return this.subscribeToModalEvents(modalInstance, onDestroy, cleanUp);
  }
  
  public openFileTooLargeModal(viewContainerRef: ViewContainerRef): Observable<any> {
    const { modalInstance, onDestroy, cleanUp } = this.openModal(viewContainerRef, 'FILE');

    return this.subscribeToModalEvents(modalInstance, onDestroy, cleanUp);
  }

  private openModal(viewContainerRef: ViewContainerRef, modalType: string): ModalSetup {
    const mtype = modalType;
    const componentRef = viewContainerRef.createComponent(ModalComponent);
    const modalInstance = componentRef.instance;
    modalInstance.modalType = mtype;
  
    const onDestroy = new Subject<void>();
  
    const cleanUp = () => {
      onDestroy.next();
      onDestroy.complete();
      componentRef.destroy();
      console.log(`${mtype} modal destroyed`);
    };

    return { componentRef, modalInstance, onDestroy, cleanUp };
  }

  private subscribeToModalEvents(modalInstance: ModalComponent, onDestroy: Subject<void>, cleanUp: () => void, additionalActions?: () => Observable<any>): Observable<any> {
    // Subscribe to 'close' and handle it with the same cleanup logic
    modalInstance.close.pipe(
      takeUntil(onDestroy),
      catchError((error) => {
        console.error(`Error in ${modalInstance.modalType} modal close observable`, error);
        return throwError(() => error);
      }),
      finalize(cleanUp),
      take(1),
    ).subscribe();

    // Return 'confirm' observable with necessary operators and additional actions
    return modalInstance.confirm.pipe(
      takeUntil(onDestroy),
      switchMap(() => additionalActions ? additionalActions() : of(null)),
      catchError((error) => {
        console.error(`Error in ${modalInstance.modalType} modal confirm observable`, error);
        return throwError(() => error);
      }),
      finalize(cleanUp),
      take(1),
    );
  }
}
