import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  getDoc,
  increment,
  updateDoc
} from '@angular/fire/firestore';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  public accessGranted = false;
  public publicData: any;

  constructor(private fireStore: Firestore) {}

  public incrementAccess(accessCode: string) :Observable<boolean> {
    const publicDataRef = doc(this.fireStore, `public_data/${accessCode}`);
    return from(
      updateDoc(publicDataRef, {
        accessCount: increment(1)
      })
    ).pipe(
      switchMap(() => from(getDoc(publicDataRef))), // Fetch the updated document
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          this.accessGranted = true;
          this.publicData = docSnapshot.data();   
        } else {
          this.accessGranted = false;
        }
        return this.accessGranted 
      }),
      catchError((err) => {
        this.accessGranted = false;
        return of(this.accessGranted);
      })
    );
  }
  
  public getIsPreviewActive(accessCode: string):Observable<boolean>{
    const previewActiveRef = doc(this.fireStore, `public_data/${accessCode}/preview/active`);

    return from(getDoc(previewActiveRef)).pipe(map(
      (doc)=>{
       return true
      }
    ),
    catchError((err) => {
      return of(false);
    })
  )

  }
}
